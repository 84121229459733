import React from 'react';
import PropTypes from 'prop-types';
import { Button, COMPONENTS } from 'sw-ui';

import { formatDate } from '../../../../../../../utils/date';

import { LABELS_STATUS_EVENTS, STATUS_EVENTS } from '../../../../../../../bi/constants/company';

import styles from '../../../styles/dialog.module.css';

const LABELS = {
  BUTTON: {
    DELETE: 'Удалить',
    CANCEL: 'Отменить',
  },
};

const TableEvents = ({
  events,
  onDelete,
  onCalcelled,
}) => {
  const renderButton = (status, id) => {
    if (status !== STATUS_EVENTS.Cancelled && status !== STATUS_EVENTS.Created) {
      return null;
    }

    const title = status === STATUS_EVENTS.Cancelled ? LABELS.BUTTON.DELETE : LABELS.BUTTON.CANCEL;
    const onClick = value => status === STATUS_EVENTS.Cancelled ? onDelete(value) : onCalcelled(value);

    return (
      <Button
        className={ styles.button }
        theme={ COMPONENTS.BUTTON.THEME.FLAT }
        onClick={ () => onClick(id) }
      >
        { title }
      </Button>
    );
  };

  return (
    <div>
      { events.map(({ Name, CreatedDate, Status, Id }) => (
        <div className={ styles.wrapper_table }>
          <div className={ styles.table }>
            <div className={ styles.name }>
              { Name }
            </div>
            <div>
              { formatDate(CreatedDate) }
            </div>
            <div className={ styles.status }>
              { LABELS_STATUS_EVENTS[Status] }
            </div>
            { renderButton(Status, Id) }
          </div>
        </div>
      ))}
    </div>
  );
};

TableEvents.propTypes = {
  events: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCalcelled: PropTypes.func.isRequired,
};

export default TableEvents;
