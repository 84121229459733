import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Tooltip, Select } from 'sw-ui';
import DatePicker from 'react-datetime';

import { CurrencyInfoBlock } from '../../../../trip/components/hotel/components/CurrencyInfoBlock';
import Input from '../../../../../components/input';
import { Button, FlatButton } from '../../../../../components/button';

import { formatDate } from '../../../../../bi/utils/formatDate';
import { isEmptyPenalties } from '../../../../../bi/utils/trip';

import COMPONENTS from '../../../../../bi/constants/components';
import { FIELDS, FUNDSFIELDS, TRIPSLABELS, PRICELABELS } from '../../../../../bi/constants/trips';
import { CURRENCY_VALUES } from '../../../../../bi/constants/hotel';
import {
  DEFAULTDATE,
  DEFAULTTIME,
  DATE_WITHOUT_TIME_ZONE,
} from '../../../../../constants/time';

import styles from '../styles/dialog.module.css';

const DIALOG_WIDTH = '60%';

const CURRENCY_VALUES_SELECT = [
  { label: CURRENCY_VALUES.USD, value: CURRENCY_VALUES.USD },
];

const PROVIDERS_VALUES = [
  { label: 'Expedia', value: 'expedia' },
];

const LABELS = {
  TITLE: 'Калькулятор валют',
  SELECT_CURRENCY: 'Выберите валюту',
  SELECT_PROVIDER: 'Выберите провайдера',
  RESERVATION_SUM_TOTAL: sum => `Сумма брони для клиента (Total): ${sum} ₽`,
  RESERVATION_SUM_BASE: sum => `Cумма брони для SW (Base): ${sum} ₽`,
  PERIOD_PENALTY: 'Период штрафа',
  PENALTY_SUM_TOTAL: sum => `Сумма штрафа для клиента (Total): ${sum} ₽`,
  PENALTY_SUM_BASE: sum => `Cумма штрафа для SW (Base): ${sum} ₽`,
  ATTENTION_CHANGE_CURRENCY: 'Обратите внимание, курс изменяется каждый день в 00:00',
  CURRENCIES: 'Валюты',
  PENALTIES: 'Штрафы',
  ADD_PENALTY: 'Добавить штраф +',
  DELETE_PENALTY: '- Удалить штраф',
  INCLUSIVE: 'Inclusive',
  MF_CURRENCY: 'Marketing Fee в валюте',
  INCLUSIVE_PENALTY: 'Inclusive в валюте',
};

const {
  INPUT: { TYPE: { NUMBER } },
  SELECT: { THEME: { BORDER } },
} = COMPONENTS;

const CalculateCurrency = ({
  currencyInfo,
  accountId,
  accountService: {
    getExchangeCurrency,
    updateCurrencyInfo,
    getPricesBookingMarkup,
    addPenaltyCurrency,
    upadatePenaltiesCurrency,
    removePenaltyCurrency,
    resetFormCurrency,
  },
  onClose,
}) => {
  const {
    Base,
    Total,
    CurrencyName,
    Provider,
    MFInCurrency,
    Inclusive,
    Penalties,
    RequiredTotalInRub,
  } = currencyInfo;

  const disabledCountAmount = !MFInCurrency || !Inclusive || isEmptyPenalties(Penalties);

  const [showTooltipCalculate, setShowTooltipCalculate] = useState(false);

  useEffect(() => {
    getExchangeCurrency(CurrencyName);

    return () => resetFormCurrency();
  }, []);

  const handleCalculateCurrency = async () => {
    const data = {
      AccountId: accountId,
      CountryCode: '',
      Provider,
      Penalties: Penalties.map(item => ({
        ...item,
        From: formatDate(item.From, DATE_WITHOUT_TIME_ZONE),
      })),
      ProviderPrice: Inclusive,
      MF: MFInCurrency,
      Currency: CurrencyName,
      RequiredTotalInRub: RequiredTotalInRub || 0,
    };

    await getPricesBookingMarkup(data);
  };

  const renderTooltipCalculate = () => {
    if (!showTooltipCalculate || !disabledCountAmount) return null;

    const textTooltip = [];

    if (!Inclusive) {
      textTooltip.push(TRIPSLABELS.ADD_INCLUSIVE);
    }
    if (!MFInCurrency) {
      textTooltip.push(TRIPSLABELS.ADD_MF);
    }
    if (isEmptyPenalties(Penalties)) {
      textTooltip.push(TRIPSLABELS.ADD_PENALTIES);
    }

    return <Tooltip>{ textTooltip.join('. ') }</Tooltip>;
  };

  const renderPenalties = () => Penalties.map(({
    From,
    ProviderPrice,
    Total: TotalCurrency,
    Base: BaseCurrency,
  }, idx) => {
    const dividerHtml = idx !== Penalties.length - 1 ? (
      <div className={ styles.divider } />
    ) : null;

    return (
      <div key={ idx }>
        <div className={ styles.input_row }>
          <div className={ styles.datepicker }>
            <DatePicker
              dateFormat={ DEFAULTDATE }
              timeFormat={ DEFAULTTIME }
              value={ From }
              onChange={ value => upadatePenaltiesCurrency(FIELDS.FROM_PENALTY, value, idx) }
              className='componentHook'
            />
            <label>{ LABELS.PERIOD_PENALTY }</label>
          </div>
          <Input
            field={ FIELDS.INCLUSIVE_CURRENCY_PENALTY }
            type={ NUMBER }
            value={ ProviderPrice }
            label={ LABELS.INCLUSIVE }
            onChange={ (e, field, value) => upadatePenaltiesCurrency(field, value, idx) }
          />
          <FlatButton
            label={ LABELS.DELETE_PENALTY }
            onClick={ () => removePenaltyCurrency(idx) }
          />
        </div>
        <div>
          <p>{ LABELS.PENALTY_SUM_TOTAL(TotalCurrency) }</p>
          <p>{ LABELS.PENALTY_SUM_BASE(BaseCurrency) }</p>
        </div>
        { dividerHtml }
      </div>
    );
  });

  return (
    <Dialog onClick={ onClose } width={ DIALOG_WIDTH } showCloseButton>
      <div className={ styles.dialog }>
        <h4>{ LABELS.TITLE }</h4>
        <div className={ styles.input_row }>
          <Select
            label={ LABELS.SELECT_CURRENCY }
            items={ CURRENCY_VALUES_SELECT }
            value={ CurrencyName }
            theme={ BORDER }
            onChange={ ({ value }) => updateCurrencyInfo(FIELDS.CURRENCY_NAME, value) }
          />
          <Select
            label={ LABELS.SELECT_PROVIDER }
            items={ PROVIDERS_VALUES }
            value={ Provider }
            theme={ BORDER }
            onChange={ ({ value }) => updateCurrencyInfo(FIELDS.PROVIDER, value) }
          />
          <CurrencyInfoBlock currency={ currencyInfo } />
        </div>
        <h4 className={ styles.info }>{ LABELS.ATTENTION_CHANGE_CURRENCY }</h4>
        <h4>{ LABELS.CURRENCIES }</h4>
        <div className={ styles.input_row }>
          <Input
            field={ FUNDSFIELDS.MF_CURRENCY }
            type={ NUMBER }
            value={ MFInCurrency }
            label={ LABELS.MF_CURRENCY }
            onChange={ (e, field, value) => updateCurrencyInfo(field, value) }
          />
          <Input
            field={ FUNDSFIELDS.INCLUSIVE }
            type={ NUMBER }
            value={ Inclusive }
            label={ LABELS.INCLUSIVE_PENALTY }
            onChange={ (e, field, value) => updateCurrencyInfo(field, value) }
          />
          <Input
            field={ FUNDSFIELDS.TOTAL_RUB }
            type={ NUMBER }
            value={ RequiredTotalInRub }
            label={ PRICELABELS.TOTAL_RUB }
            onChange={ (e, field, value) => updateCurrencyInfo(field, value) }
          />
        </div>
        <div>
          <p>{ LABELS.RESERVATION_SUM_TOTAL(Total) }</p>
          <p>{ LABELS.RESERVATION_SUM_BASE(Base) }</p>
        </div>
        <h4>{ LABELS.PENALTIES }</h4>
        { renderPenalties() }
        <div className={ styles.button_add_penalty }>
          <FlatButton
            label={ LABELS.ADD_PENALTY }
            onClick={ addPenaltyCurrency }
          />
        </div>
        <div className={ styles.button_calculate }>
          <div className='sw-tooltip-wrapper'>
            <div
              onMouseEnter={ () => setShowTooltipCalculate(true) }
              onMouseLeave={ () => setShowTooltipCalculate(false) }
            >
              <Button
                label={ PRICELABELS.CALCULATE_CURRENCY }
                onClick={ handleCalculateCurrency }
                disabled={ disabledCountAmount }
              />
            </div>
            { renderTooltipCalculate(disabledCountAmount) }
          </div>
        </div>
      </div>
    </Dialog>
  );
};

CalculateCurrency.propTypes = {
  currencyInfo: PropTypes.object.isRequired,
  accountService: PropTypes.object.isRequired,
  accountId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { CalculateCurrency };
