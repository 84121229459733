import React from 'react';
import PropTypes from 'prop-types';

import { SORTING_EVENTS } from '../../../../../../../bi/constants/company';

import styles from '../../../styles/dialog.module.css';

const LABELS = {
  TABLE: {
    NAME: 'Название заявки',
    DATE: 'Дата создания',
    STATUS: 'Статус',
  },
};

const HeaderTableEvents = ({
  onSorting,
  typeSort,
}) => {
  const renderIcons = (type) => {
    const colorIcon = type !== typeSort ? 'silver' : 'black';

    return (
      <div className={ styles.icons }>
        <i
          style={ { color: colorIcon, fontSize: '20px' } }
          className='material-icons'
        >
          arrow_downward
        </i>
      </div>
    );
  };

  return (
    <div className={ styles.table_default }>
      <div
        className={ styles.point }
        onClick={ () => onSorting(SORTING_EVENTS.NAME) }
      >
        <h3>{ LABELS.TABLE.NAME }</h3>
        { renderIcons(SORTING_EVENTS.NAME) }
      </div>
      <div
        className={ styles.point }
        onClick={ () => onSorting(SORTING_EVENTS.DATE) }
      >
        <h3>{ LABELS.TABLE.DATE }</h3>
        { renderIcons(SORTING_EVENTS.DATE) }
      </div>
      <div
        className={ styles.point }
        onClick={ () => onSorting(SORTING_EVENTS.STATUS) }
      >
        <h3>{ LABELS.TABLE.STATUS }</h3>
        { renderIcons(SORTING_EVENTS.STATUS) }
      </div>
    </div>
  );
};

HeaderTableEvents.propTypes = {
  typeSort: PropTypes.string.isRequired,
  onSorting: PropTypes.func.isRequired,
};

export default HeaderTableEvents;
